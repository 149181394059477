import { mdiChevronRight } from '@mdi/js';
import { Icon as MuiIcon } from '@mdi/react';
import { PopoverOrigin } from '@mui/material';
import { createTheme } from 'src/lib/style/theme';
import palette, { Color } from './constants/palette';

export const baseTheme = createTheme({
    palette: {
        primary: {
            main: palette.primary,
            contrastText: palette.textLight,
            text: Color.Orange600,
            background: Color.Orange100,
        },
        secondary: {
            main: palette.primaryBlue,
            contrastText: palette.textLight,
            text: Color.Blue600,
            background: Color.Blue100,
        },
        danger: {
            main: palette.danger,
            contrastText: palette.textLight,
            background: Color.Red100,
            text: Color.Red600,
        },
        success: {
            main: Color.Green600,
            contrastText: Color.White,
            background: Color.Green100,
            text: Color.Green600,
        },
        warning: {
            main: Color.Yellow600,
            contrastText: Color.White,
            background: Color.Yellow100,
            text: Color.Yellow600,
        },
        info: {
            main: Color.Blue500,
            contrastText: Color.White,
            background: Color.Blue100,
            text: Color.Blue500,
        },
        neutral: {
            main: palette.neutral,
            contrastText: palette.textPrimary,
            background: palette.neutral,
            text: palette.textPrimary,
        },
        error: {
            main: Color.Red500,
            contrastText: Color.White,
            background: Color.Red100,
            text: Color.Red500,
        },
        payment: {
            main: palette.secondaryGreen,
            contrastText: palette.textLight,
            background: Color.Green100,
            text: Color.Green600,
        },
        text: {
            primary: palette.textPrimary,
            secondary: palette.textSecondary,
            hint: palette.textPlaceholder,
        },
        accent: {
            variant1: palette.primaryPurple,
            variant2: palette.primaryTeal,
        },
        divider: Color.Grey200,
        background: {
            default: palette.background,
        },
    },
    typography: {
        fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        h1: {
            fontSize: '24px',
            fontWeight: 600,
            color: palette.textPrimary,
        },
        h2: {
            fontSize: '20px',
            fontWeight: 600,
            color: palette.textPrimary,
        },
        h3: {
            fontSize: '18px',
            fontWeight: 600,
            color: palette.textPrimary,
            lineHeight: '1.5',
        },
        h4: {
            fontSize: '16px',
            fontWeight: 600,
            color: palette.textPrimary,
            lineHeight: '1.5',
        },
        h5: {
            fontSize: '14px',
            fontWeight: 500,
            color: palette.textPrimary,
            lineHeight: '1.5',
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 400,
            color: palette.textSecondary,
            lineHeight: '1.5',
        },
        subtitle2: {
            fontSize: '14px',
            fontWeight: 400,
            color: palette.textSecondary,
            lineHeight: '1.5',
        },
        caption: {
            fontSize: '13px',
            fontWeight: 400,
            color: palette.textSecondary,
            lineHeight: '1.5',
        },
        body1: {
            fontSize: '16px',
            fontWeight: 400,
            color: palette.textPrimary,
            lineHeight: '1.5',
        },
        body2: {
            fontSize: '14px',
            fontWeight: 400,
            color: palette.textPrimary,
            lineHeight: '1.5',
        },
        body3: {
            display: 'block',
            fontSize: '13px',
            fontWeight: 400,
            color: palette.textSecondary,
            lineHeight: '1.3',
        },
    },
});

baseTheme.components = {
    MuiDialog: {
        defaultProps: {
            fullWidth: true,
        },
    },
    MuiCollapse: {
        defaultProps: {
            timeout: 375,
        },
    },
    MuiAppBar: {
        styleOverrides: {
            root: {
                colorPrimary: {
                    color: baseTheme.palette.text.primary,
                },
            },
        },
    },
    MuiBreadcrumbs: {
        defaultProps: {
            separator: <MuiIcon size="24px" path={mdiChevronRight} />,
        },
        styleOverrides: {
            li: {
                '&:last-child': {
                    color: baseTheme.palette.text.primary,
                },
                '& p': {
                    fontWeight: 600,
                },
                '& svg': {
                    marginRight: baseTheme.spacing(1.5),
                },
            },
            root: {
                marginBottom: baseTheme.spacing(4),
                fontWeight: 600,
                '& a': {
                    color: palette.link,
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
            separator: {
                marginRight: baseTheme.spacing(0.5),
                marginLeft: baseTheme.spacing(0.5),
            },
        },
    },
    MuiButton: {
        defaultProps: {
            color: 'neutral',
        },
        styleOverrides: {
            contained: {
                boxShadow: 'none',
            },
            root: {
                textTransform: 'unset',
                transitionProperty: 'background-color, box-shadow, border, color',
                whiteSpace: 'nowrap',
                '&.MuiButton-textNeutral': {
                    color: baseTheme.palette.text.primary,
                },
                '&.MuiButton-outlinedNeutral': {
                    color: baseTheme.palette.text.primary,
                },
            },
        },
    },
    MuiAlert: {
        styleOverrides: {
            standardInfo: {
                backgroundColor: baseTheme.resqPalette.surface.highlightSubdued,
                border: `1px solid ${baseTheme.resqPalette.border.highlight}`,
                color: baseTheme.resqPalette.text.default,
                '.MuiAlert-icon': {
                    color: baseTheme.resqPalette.icon.highlight,
                },
            },
            standardError: {
                backgroundColor: baseTheme.resqPalette.surface.criticalSubdued,
                border: `1px solid ${baseTheme.resqPalette.border.critical}`,
                '.MuiAlert-icon': {
                    color: baseTheme.resqPalette.icon.critical,
                },
            },
            standardWarning: {
                backgroundColor: baseTheme.resqPalette.surface.warningSubdued,
                border: `1px solid ${baseTheme.resqPalette.border.warning}`,
                '.MuiAlert-icon': {
                    color: baseTheme.resqPalette.icon.warning,
                },
            },
            root: {
                paddingTop: 0,
                paddingBottom: 0,
                '.MuiButton-textNeutral': {
                    marginTop: baseTheme.spacing(1),
                    color: `${palette.warningHiglightBrown} !important`,
                    borderColor: palette.warningHiglightBrown,
                    boxShadow: baseTheme.spacing(10),
                    '&:hover': {
                        borderColor: palette.warningHiglightBrown,
                    },
                },
                '.MuiButton-outlinedNeutral': {
                    marginTop: baseTheme.spacing(1),
                    color: `${palette.warningHiglightBrown} !important`,
                    borderColor: palette.warningHiglightBrown,
                    boxShadow: baseTheme.spacing(10),
                    '&:hover': {
                        borderColor: palette.warningHiglightBrown,
                    },
                },
            },
        },
    },
    MuiButtonGroup: {
        styleOverrides: {
            contained: {
                boxShadow: 'none',
            },
        },
    },

    MuiFormLabel: {
        styleOverrides: {
            root: {
                color: baseTheme.palette.text.primary,
                '&.Mui-disabled': {
                    color: baseTheme.palette.text.primary,
                },
            },
        },
    },
    MuiInput: {
        styleOverrides: {
            underline: {
                '&.Mui-disabled::before': {
                    borderBottomColor: 'transparent',
                    borderBottomStyle: 'solid',
                },
            },
        },
    },
    MuiInputAdornment: {
        styleOverrides: {
            positionStart: {
                marginRight: 0,
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            input: {
                '&::-moz-placeholder': {
                    color: baseTheme.palette.text.hint,
                    opacity: 1,
                },
                '&:-ms-input-placeholder': {
                    color: baseTheme.palette.text.hint,
                    opacity: 1,
                },
                '&::-webkit-input-placeholder': {
                    color: baseTheme.palette.text.hint,
                    opacity: 1,
                },
            },
            root: {
                '&.Mui-disabled': {
                    color: baseTheme.palette.text.primary,
                    '& .MuiSelect-icon': {
                        opacity: 0,
                    },
                    '& .MuiAutocomplete-endAdornment': {
                        opacity: 0,
                    },
                },
            },
        },
    },
    MuiFormControl: {
        defaultProps: {
            variant: 'standard',
        },
    },
    MuiTextField: {
        defaultProps: {
            variant: 'standard',
            fullWidth: true,
        },
    },
    MuiOutlinedInput: {
        defaultProps: {
            notched: false,
            label: null,
        },
        styleOverrides: {
            root: {
                '[disabled]': {
                    backgroundColor: Color.Grey50,
                    color: Color.Grey400,
                },
            },
        },
    },
    MuiInputLabel: {
        defaultProps: {
            shrink: true,
        },
        styleOverrides: {
            root: {
                color: baseTheme.palette.text.primary,
            },
        },
    },
    MuiPaper: {
        styleOverrides: {
            rounded: {
                borderRadius: '8px',
                boxShadow: `0 4px 8px ${palette.shadow} !important`,
                overflow: 'hidden',
                [baseTheme.breakpoints.down('lg')]: {
                    borderRadius: '0',
                    borderColor: 'transparent',
                    boxShadow: 'none !important',
                },
            },
        },
    },
    MuiSelect: {
        defaultProps: {
            variant: 'standard',
            MenuProps: {
                anchorOrigin: {
                    vertical: 'bottom' as PopoverOrigin['vertical'],
                    horizontal: 'center' as PopoverOrigin['horizontal'],
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
            },
        },
        styleOverrides: {
            icon: {
                width: '0.85em',
                height: '0.85em',
                top: 'calc(50% - 10px)',
            },
            select: {
                '&.Mui-disabled': {
                    userSelect: 'auto',
                    backgroundColor: Color.Grey50,
                    color: Color.Grey400,
                },
            },
        },
    },
    MuiSvgIcon: {
        styleOverrides: {
            fontSizeLarge: {
                fontSize: 28,
            },
        },
    },
    MuiListItemText: {
        styleOverrides: {
            root: {
                marginTop: baseTheme.spacing(1),
                marginBottom: baseTheme.spacing(1),
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                textTransform: 'unset',
                fontSize: '16px',
                fontWeight: 600,
                opacity: 1,
                color: baseTheme.palette.text.secondary,
                minWidth: 'unset !important',
                maxWidth: 'unset !important',
                minHeight: 'unset !important',
                lineHeight: 'unset !important',
                padding: baseTheme.spacing(0, 0, 1),
                marginRight: baseTheme.spacing(4),
                '&.Mui-focused': {
                    color: baseTheme.palette.text.primary,
                },
                '&.MuiTab-textColorSecondary.Mui-selected': {
                    color: baseTheme.palette.text.primary,
                },
            },
        },
    },
    MuiTabs: {
        defaultProps: {
            indicatorColor: 'secondary',
            textColor: 'secondary',
        },

        styleOverrides: {
            indicator: {
                height: '3px',
            },
            root: {
                minHeight: 'unset',
                [baseTheme.breakpoints.down('lg')]: {
                    paddingLeft: baseTheme.spacing(4),
                    paddingRight: baseTheme.spacing(4),
                },
            },
        },
    },
    MuiTable: {
        styleOverrides: {
            root: {
                borderCollapse: 'separate',
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            head: {
                backgroundColor: Color.Grey50,
                color: baseTheme.palette.text.primary,
                fontSize: '14px',
                fontWeight: 500,
                verticalAlign: 'bottom',
                lineHeight: 1.3,
            },
            body: {
                borderTop: `1px solid ${baseTheme.palette.divider}`,
            },
            footer: {
                borderTop: `1px solid ${baseTheme.palette.divider}`,
            },
            root: {
                borderBottom: 'none',
                '& a': {
                    textDecoration: 'underline',
                },
                '&:first-of-type': {
                    paddingLeft: baseTheme.spacing(4),
                },
                '&:last-child': {
                    paddingRight: baseTheme.spacing(4),
                },
            },
        },
    },
    MuiTablePagination: {
        styleOverrides: {
            toolbar: {
                padding: baseTheme.spacing(0, 4),
                justifyContent: 'flex-end',
                flexWrap: 'wrap',
            },
            spacer: {
                display: 'none',
            },
        },
    },
    MuiToolbar: {
        styleOverrides: {
            regular: {
                [baseTheme.breakpoints.up('sm')]: {
                    minHeight: '48px !important',
                },
            },
        },
    },
    MuiTooltip: {
        styleOverrides: {
            tooltip: {
                fontSize: '12px',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
        },
    },
    MuiTypography: {
        styleOverrides: {
            body1: {
                '& a': {
                    textDecoration: 'underline',
                },
            },
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                padding: baseTheme.spacing(3, 4),
                fontWeight: 600,
                fontSize: '1.375rem',
                [baseTheme.breakpoints.down('md')]: {
                    padding: baseTheme.spacing(4, 3, 3),
                },
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                padding: baseTheme.spacing(4),
                [baseTheme.breakpoints.down('md')]: {
                    padding: baseTheme.spacing(4, 3),
                },
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                padding: baseTheme.spacing(0, 4, 3),
                justifyContent: 'space-between',
                [baseTheme.breakpoints.down('md')]: {
                    padding: baseTheme.spacing(2, 3),
                    backgroundColor: baseTheme.palette.background.default,
                },
            },
        },
    },
};

import {
    CircularProgress,
    Button as MuiButton,
    ButtonProps as MuiButtonProps,
    styled,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTrack } from 'src/lib/analytics';

import {
    AnalyticsEvent,
    AnalyticsEventType,
    ClickElementType,
    DisplayElementType,
} from '../analytics/events';
import { Typography } from './Typography';

export type ButtonKind = 'filled' | 'outlined' | 'text';

export type ButtonColor = 'primary' | 'secondary' | 'critical' | 'success' | 'inherit';

export type ButtonSize = 'small' | 'standard' | 'large';

const StyledButton = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== '$splitButtonArrow' && prop !== '$size',
})<{ $splitButtonArrow: boolean; $size: ButtonSize }>`
    padding: ${({ theme, $splitButtonArrow, $size }) => {
        const sizeFactor = $size === 'small' ? 0.75 : $size === 'large' ? 2.75 : 1;
        const rightLeft = $splitButtonArrow ? 1 : 2;
        return theme.spacing(1 * sizeFactor, rightLeft * sizeFactor);
    }};
    max-height: ${({ theme, $size }) => {
        return $size === 'small' ? theme.spacing(3.375) : theme.spacing(4.25);
    }};
    &.MuiButton-containedPrimary {
        color: ${({ theme }) => theme.resqPalette.text.onPrimary};
        background-color: ${({ theme }) => theme.resqPalette.surface.primary};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.primaryHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.primaryPressed};
        }
        &[disabled] {
            background-color: ${({ theme }) => theme.resqPalette.surface.primaryDisabled};
        }
    }
    &.MuiButton-containedSecondary {
        color: ${({ theme }) => theme.resqPalette.text.onSecondary};
        background-color: ${({ theme }) => theme.resqPalette.surface.secondary};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.secondaryHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.secondaryPressed};
        }
        &[disabled] {
            background-color: ${({ theme }) => theme.resqPalette.surface.secondaryDisabled};
            color: ${({ theme }) => theme.resqPalette.text.disabled};
        }
    }
    &.MuiButton-containedDanger {
        color: ${({ theme }) => theme.resqPalette.text.onCritical};
        background-color: ${({ theme }) => theme.resqPalette.surface.critical};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.criticalHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.criticalPressed};
        }
        &[disabled] {
            background-color: ${({ theme }) => theme.resqPalette.surface.criticalDisabled};
        }
    }
    &.MuiButton-containedSuccess {
        color: ${({ theme }) => theme.resqPalette.text.onSuccess};
        background-color: ${({ theme }) => theme.resqPalette.surface.success};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.successHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.successPressed};
        }
        &[disabled] {
            background-color: ${({ theme }) => theme.resqPalette.surface.successDisabled};
        }
    }
    &.MuiButton-outlinedPrimary {
        color: ${({ theme }) => theme.resqPalette.text.primary};
        border-color: ${({ theme }) => theme.resqPalette.border.primary};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.primarySubduedHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.primarySubduedPressed};
            color: ${({ theme }) => theme.resqPalette.text.primaryPressed};
        }
        &[disabled] {
            border-color: ${({ theme }) => theme.resqPalette.border.primaryDisabled};
            color: ${({ theme }) => theme.resqPalette.text.primaryDisabled};
        }
    }
    &.MuiButton-outlinedDanger {
        color: ${({ theme }) => theme.resqPalette.text.critical};
        border-color: ${({ theme }) => theme.resqPalette.border.critical};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.criticalSubduedHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.criticalSubduedPressed};
            color: ${({ theme }) => theme.resqPalette.text.criticalPressed};
        }
        &[disabled] {
            border-color: ${({ theme }) => theme.resqPalette.border.criticalDisabled};
            color: ${({ theme }) => theme.resqPalette.text.criticalDisabled};
        }
    }
    &.MuiButton-outlinedSuccess {
        color: ${({ theme }) => theme.resqPalette.text.success};
        border-color: ${({ theme }) => theme.resqPalette.border.success};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.successSubduedHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.successSubduedPressed};
            color: ${({ theme }) => theme.resqPalette.text.successPressed};
        }
        &[disabled] {
            border-color: ${({ theme }) => theme.resqPalette.border.successDisabled};
            color: ${({ theme }) => theme.resqPalette.text.successDisabled};
        }
    }
    &.MuiButton-textPrimary {
        color: ${({ theme }) => theme.resqPalette.text.primary};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.primarySubduedHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.primarySubduedPressed};
        }
        &[disabled] {
            color: ${({ theme }) => theme.resqPalette.text.primaryDisabled};
        }
    }
    &.MuiButton-textDanger {
        color: ${({ theme }) => theme.resqPalette.text.critical};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.criticalSubduedHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.criticalSubduedPressed};
        }
        &[disabled] {
            color: ${({ theme }) => theme.resqPalette.text.criticalDisabled};
        }
    }
    &.MuiButton-textSuccess {
        color: ${({ theme }) => theme.resqPalette.text.success};
        :focus-visible,
        :hover {
            background-color: ${({ theme }) => theme.resqPalette.surface.successSubduedHovered};
        }
        :active {
            background-color: ${({ theme }) => theme.resqPalette.surface.successSubduedPressed};
        }
        &[disabled] {
            color: ${({ theme }) => theme.resqPalette.text.successDisabled};
        }
    }
`;

StyledButton.defaultProps = {
    disableRipple: true,
    disableElevation: true,
};

export type BaseButtonProps = Pick<
    MuiButtonProps,
    'children' | 'onClick' | 'type' | 'fullWidth' | 'startIcon' | 'endIcon' | 'disabled'
> & {
    size?: ButtonSize;
    trackingId?: string;
    loading?: boolean;
    splitButtonArrow?: boolean;
};

export type FilledButtonProps = {
    kind: 'filled';
    color: ButtonColor;
};

export type NonFilledButtonProps = {
    kind: Exclude<ButtonKind, 'filled'>;
    color: ButtonColor;
};

export type ButtonProps = BaseButtonProps & (FilledButtonProps | NonFilledButtonProps);

function mapVariant(kind: ButtonKind) {
    switch (kind) {
        case 'filled':
            return 'contained';
        default:
            return kind;
    }
}

function mapColor(color: ButtonColor) {
    switch (color) {
        case 'critical':
            return 'danger';
        default:
            return color;
    }
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            children,
            kind,
            color,
            size = 'standard',
            onClick,
            type = 'button',
            fullWidth,
            startIcon,
            endIcon,
            disabled,
            trackingId,
            loading,
            splitButtonArrow = false,
        },
        ref,
    ) => {
        const track = useTrack();
        useEffect(() => {
            track(AnalyticsEvent.ElementDisplayed, {
                trackingId: trackingId,
                label: splitButtonArrow ? 'Arrow Icon' : children?.toString() || null,
                elementType: DisplayElementType.BUTTON,
                eventType: AnalyticsEventType.ANALYTICS,
                link: null,
                option: null,
                disabled: disabled,
            });
        }, [children, splitButtonArrow, track, trackingId, disabled]);

        return (
            <StyledButton
                ref={ref}
                type={type}
                $size={size}
                variant={mapVariant(kind)}
                color={mapColor(color)}
                fullWidth={fullWidth}
                startIcon={loading ? <CircularProgress size={17} color="inherit" /> : startIcon}
                endIcon={endIcon}
                disabled={disabled || loading}
                $splitButtonArrow={splitButtonArrow}
                onClick={(event) => {
                    onClick?.(event);
                    track(AnalyticsEvent.ElementClicked, {
                        trackingId,
                        label: splitButtonArrow ? 'Arrow Icon' : children?.toString() || null,
                        elementType: ClickElementType.BUTTON,
                        eventType: AnalyticsEventType.ANALYTICS,
                        option: null,
                        link: null,
                    });
                }}
                data-button-name={children?.toString()}
            >
                <Typography role={size === 'small' ? 'button-small' : 'button'} color="inherit">
                    {children}
                </Typography>
            </StyledButton>
        );
    },
);

import { Alert, styled, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CustomContentProps, SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import React from 'react';
import { CheckCircle1AlternateIcon, ErrorIconBold, InformationCircleIconBold } from 'src/icons';

// Do not copy, prefer styled()
// https://www.notion.so/getresq/Front-end-53edccaf633544a589495a1d35cbbe43?pvs=4#271379db374c4bb2a257014f5ee8ff75
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '0px',
    },
}));

const SnackbarAlert = styled(Alert)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    '& .MuiAlert-message': {
        fontWeight: 600,
        padding: theme.spacing(1, 0),
        lineHeight: 1.25,
    },
    '& .MuiAlert-icon': {
        marginRight: theme.spacing(1),
        opacity: 1,
    },
    '& .MuiAlert-action': {
        alignItems: 'center',
    },
}));

const SnackbarContent = React.forwardRef<HTMLDivElement, CustomContentProps>(
    ({ id, message, style, variant, action }, ref) => {
        const theme = useTheme();
        return (
            <SnackbarAlert
                style={style}
                severity={variant === 'default' ? 'info' : variant}
                variant="filled"
                ref={ref}
                iconMapping={{
                    error: <ErrorIconBold height="18px" fill={theme.palette.info.contrastText} />,
                    info: (
                        <InformationCircleIconBold
                            height="18px"
                            fill={theme.palette.info.contrastText}
                        />
                    ),
                    success: (
                        <CheckCircle1AlternateIcon
                            height="18px"
                            fill={theme.palette.info.contrastText}
                        />
                    ),
                }}
                action={typeof action === 'function' ? action(id) : action}
            >
                {message}
            </SnackbarAlert>
        );
    },
);

interface SnackbarProviderProps {
    children: React.ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const styles = useStyles();
    return (
        <NotistackSnackbarProvider
            classes={styles}
            dense
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            Components={{
                success: SnackbarContent,
                error: SnackbarContent,
                warning: SnackbarContent,
                info: SnackbarContent,
                default: SnackbarContent,
            }}
        >
            {children}
        </NotistackSnackbarProvider>
    );
};
